import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Parnell({ styles, ...props }) {
  const Elements = useComponents()

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div className="formkit-section formkit-section--cover">
        <div
          className="formkit-background"
          style={styles.forElement("upper_background")}
        >
          <div className="formkit-container">
            <div className="formkit-column">
              <header>
                <Elements.Content
                  className="formkit-preheader"
                  name="preheader"
                  defaults={{
                    content: "New ebook"
                  }}
                />
                <Elements.Heading
                  className="formkit-header"
                  name="header"
                  defaults={{
                    content: "Get my free book now!"
                  }}
                />
              </header>
              <Elements.Region
                className="formkit-form-content"
                name="form_content"
              >
                <Elements.Content
                  defaults={{
                    content:
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a aliquet justo. Suspendisse massa."
                  }}
                />
              </Elements.Region>
              <Elements.Form>
                <Elements.Errors />
                <Elements.CustomFields>
                  <Elements.AddFieldButton />
                  <Elements.Button
                    name="submit"
                    group="button"
                    defaults={{ content: "Download now" }}
                  />
                </Elements.CustomFields>
                <Elements.Content
                  name="disclaimer"
                  defaults={{
                    content: "We respect your privacy. Unsubscribe at any time."
                  }}
                />
              </Elements.Form>
            </div>
            <div className="formkit-column">
              <Elements.Image
                className="formkit-primary-image relative"
                name="image"
                size={{ w: 450, h: 715 }}
              />
            </div>
          </div>
        </div>
        <div className="formkit-secondary-background" />
      </div>
      <div
        className="formkit-section"
        style={styles.forElement("lower_background")}
      >
        <div className="formkit-container">
          <div className="formkit-column">
            <div
              className="formkit-card"
              style={styles.forElement("testimonial_background")}
            >
              <Elements.Content
                className="formkit-testimonial"
                name="testimonial_content"
                defaults={{
                  content:
                    "“Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi tristique imperdiet turpis fringilla varius. Morbi maximus ultricies risus vitae sagittis.”"
                }}
              />
              <div className="formkit-testimonial-meta">
                <Elements.Image
                  className="formkit-avatar"
                  name="avatar"
                  size={{ w: 40, h: 40 }}
                  aspectRatio={1}
                  shape="circle"
                />
                <div className="formkit-testimonial-author">
                  <Elements.Content
                    name="testimonial_contact"
                    defaults={{
                      content: "John Smith"
                    }}
                  />
                  <Elements.Content
                    name="testimonial_location"
                    defaults={{
                      content: "Chicago, IL"
                    }}
                  />
                </div>
              </div>
            </div>
            <Elements.Heading
              className="formkit-content-header"
              group="content_header"
              name="content_header"
              tag="h2"
              defaults={{
                content: "What you get:"
              }}
            />
            <Elements.Region
              className="formkit-content"
              group="content"
              name="content"
            >
              <Elements.Content
                defaults={{
                  content:
                    "Lorem ipsum dolor sit amet Consectetur adipiscing elit Sed a aliquet justo. Suspendisse massa purus, egestas ut rutrum in Interdum vel nulla"
                }}
              />
            </Elements.Region>
          </div>
          <div className="formkit-column">
            <Elements.Heading
              className="formkit-content-header"
              group="content_header"
              name="content_header_2"
              tag="h2"
              defaults={{
                content: "What is it all about?"
              }}
            />
            <Elements.Region
              className="formkit-content"
              group="content"
              name="content_2"
            >
              <Elements.Content
                defaults={{
                  content:
                    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a aliquet justo. Suspendisse massa purus, egestas ut rutrum in, interdum vel nulla. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a aliquet justo. Suspendisse massa purus, egestas ut rutrum in, interdum vel nulla.</p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a aliquet justo. Suspendisse massa purus, egestas ut rutrum in, interdum vel nulla. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a aliquet justo. Suspendisse massa purus, egestas ut rutrum in, interdum vel nulla."
                }}
              />
            </Elements.Region>
          </div>
        </div>
        <Elements.BuiltWith background="lower_background" />
      </div>
    </Elements.LandingPage>
  )
}

Parnell.style = "landing_page"
Parnell.categories = ["eBook", "Podcast", "Product"]
Parnell.thumbnail = ""
Parnell.preview = "https://demo.ck.page/parnell"
Parnell.fields = [
  {
    name: "first_name",
    label: "Full name"
  },
  {
    name: "email_address",
    label: "E-mail"
  }
]

Parnell.beta = true

export default createTemplate(Parnell, { name: "Parnell" })
